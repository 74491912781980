import React, { Fragment, useEffect, useState } from "react";
import Hero from "../../components/hero";

import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import PartnerSection from "../../components/PartnerSection";
import AboutS2 from "../../components/AboutS2";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import ProjectSection4 from "../../components/ProjectSection4";
import Service5 from "../../components/Service5";
import OurCompanies from "../../components/OurCompanies";

const HomePage = () => {
  const [homeId, setHomeId] = useState();
  const [serviceId, setServiceId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === "Home" ? setHomeId(e.id) : null));
        data.menus.find((e) =>
          e.name === "Service" ? setServiceId(e.id) : null
        );
      })
      .catch(() => {});
  }, []);
  //console.log("nnnnnnn", homeId);

  return (
    <Fragment>
      <Hero />
      <OurCompanies id={homeId} tClass={"wpo-testimonial-area-s3"} />
      <AboutS2 id={homeId} />
      <Service5 id={serviceId} />
      <ProjectSection4 id={homeId} />
      <PartnerSection id={homeId} />
      <Scrollbar />
      <Footer />
    </Fragment>
  );
};
export default HomePage;
